<template>
  <BaseTooltipModal v-if="cashBonus" :link="readMoreUrl">
    <template #title> {{ titleByLevel }} </template>
    <template #subtitle> {{ t('bonuses.depositBonus') }} </template>
    <TooltipModalBlock :title="t('bonuses.tootlipModalConditions')">
      <TooltipModalBlockItem
        :termin="t('bonuses.depositMinSum')"
        :description="minDepositAmount"
      />
    </TooltipModalBlock>
    <TooltipModalBlock :title="t('bonuses.tooltipModalParameters')">
      <TooltipModalBlockItem
        :termin="t('bonuses.maxDepositRewardAmount')"
        :description="maxDefaultBonusAmountWithCurrency"
      />
    </TooltipModalBlock>
    <TooltipModalBlock
      v-if="cashBonus.rollingSettings"
      :title="t('bonuses.tooltipModalWager')"
      data-t="multiplier-block"
    >
      <TooltipModalBlockItem :termin="t('bonuses.modalRolling')">
        x{{ rollingMultiplier }}
      </TooltipModalBlockItem>
      <TooltipModalBlockItem
        v-if="formattedRollingMinBetRate"
        :termin="t('bonuses.tooltipModalSportMin')"
        :description="formattedRollingMinBetRate"
      />
      <TooltipModalBlockItem
        v-if="formattedRollingMaxBetRate"
        :termin="t('bonuses.tooltipModalSportMax')"
        :description="formattedRollingMaxBetRate"
      />
      <TooltipModalBlockItem
        v-if="filteredCategoriesNames?.length"
        :termin="t('bonuses.freespinModalCasino')"
      >
        <div class="column">
          <span v-for="category in filteredCategoriesNames" :key="category">
            {{ category }}
          </span>
        </div>
      </TooltipModalBlockItem>
    </TooltipModalBlock>
  </BaseTooltipModal>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useCashBonusesStore } from '../../stores/useCashBonusesStore'
import { useCashBonus } from '../../composables/useCashBonus'
import { useGetTitleByLevel } from '../../composables/useGetTitleByLevel'
import { useRollingData } from '../../composables/useRollingData'
import BaseTooltipModal from '../TooltipModal/BaseTooltipModal.vue'
import TooltipModalBlock from '../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../TooltipModal/TooltipModalBlockItem.vue'
import { useBonusesDeps } from '../../useDeps'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()

const { cashBonuses } = storeToRefs(useCashBonusesStore())
const cashBonus = computed(
  () => cashBonuses.value.filter((item) => item.id === Number(props.id))[0],
)
const readMoreUrl = usePublicFileUrl('publicFileBonusForDepositTerms')

const { bonusCurrencyCode } = useCashBonus(cashBonus)

const { format: formatNumber } = useNumberFormatter()
const { getTitle } = useGetTitleByLevel()
const titleByLevel = computed(() => {
  const amount = `${formatNumber(
    new Decimal(cashBonus.value.bonusForDepositProgram?.depositMultiplier ?? 0)
      .mul(100)
      .toString(),
  )}%`
  return getTitle(amount, cashBonus.value.bonusForDepositProgram?.level)
})

const { appCurrencyCode } = useBonusesDeps()
const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrencyCode.value,
})
const minDepositAmount = computed(() => {
  const minFromAmount =
    cashBonus.value.bonusForDepositProgram?.minDepositAmountInAppCurrency ?? 0
  return formatCurrency(minFromAmount)
})

const { format: formatCryptoWithCurrencyCode } = useCryptoFormatter({
  currency: bonusCurrencyCode.value,
})

const maxDefaultBonusAmountWithCurrency = computed(() =>
  formatCryptoWithCurrencyCode(
    cashBonus.value.bonusForDepositProgram?.maxBonusAmountInBonusCurrency ?? 0,
  ),
)

const cashBonusRollingData = computed(() => {
  const {
    casinoCategoryIds: rollingCasinoCategoryIds = null,
    minBetRate: rollingMinBetRate = null,
    maxBetRate: rollingMaxBetRate = null,
    multiplier: rollingMultiplier = '0',
  } = cashBonus.value.rollingSettings ?? {}

  return {
    rollingMultiplier,
    rollingPeriodInHours: 0,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})
const {
  rollingMultiplier,
  filteredCategoriesNames,
  formattedRollingMinBetRate,
  formattedRollingMaxBetRate,
} = useRollingData(cashBonusRollingData)
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
